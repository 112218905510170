


























































































import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";
import AccountingsTable from "@/components/AccountingsTable.vue";
import PrintRow from "@/components/PrintRow.vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Accounting",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner,
    "accountings-table": AccountingsTable,
    "print-row": PrintRow
  },
  data() {
    return {
      client: null,
      year: "",
      season: null,
      brand: null,
      printRowStatus: false,
      sendPdfEmail: false,
      fields: [
        { label: "brand", text: "Brand" },
        { label: "total_sum", text: "Importo" },
        { label: "gross_import", text: "Importo lordo (con IVA)" },
        { label: "payment_method", text: "Mod. pagamento" },
        { label: "due", text: "Scadenza" },
        { label: "import_value", text: "Importo pagamento" },
        { label: "cashed", text: "Incassato" },
        { label: "residue", text: "Residuo" },
        { label: "company", text: "Azienda mandante" },
        { label: "delivered_import", text: "Consegna effettiva" },
        { label: "actual_delivery", text: "Importo consegnato" },
      ]
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("clients", ["clientsList"]),
    ...mapGetters("auth", ["me"])
  },
  async created() {
    await this.$store.dispatch("auth/findMe");
    this.$store.dispatch("brands/getBrandsList");
    this.$store.dispatch("clients/getClientsList", 1000);
    this.$store.dispatch("orders/getPaymentMethods", 1000);

    const q = this.$route.query;
    if (q.client && q.season && q.year) {
      this.$data.client = Number(q.client);
      this.$data.season = q.season;
      this.$data.year = q.year;
      this.filterAccounting();
    }
  },
  methods: {
    openPrintAccounting(email: boolean) {
      this.printRowStatus = true;
      this.sendPdfEmail = email;
    },
    filterAccounting() {
      if (this.client && this.season && this.year && this.year.length > 3) {
        let payload: any = {
          client: this.client,
          year: this.year,
          season: this.season
        };
        if (this.brand) {
          payload["brand"] = this.brand;
        }
        this.$store.dispatch("accounting/searchAccountings", payload);
      }
    },
    ...mapActions("accounting", ["filterByResidueGreaterThan0"])
  }
});
