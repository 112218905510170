




























































import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

import moment from "moment";

export default Vue.extend({
  name: "OrderPaymentNew",
  props: ["orderId"],
  data() {
    return {
      form: {
        due: "",
        order: 0,
        cashed: false,
        credit: false,
        import_value: 0
      },
      rules: {
        due: [{ required: true, message: "Campo obbligatorio" }],
        payment_method: [{ required: true, message: "Campo obbligatorio" }],
        import_value: [{ required: true, message: "Campo obbligatorio" }]
      }
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("orders", ["paymentMethods"]),
    ...mapGetters("accounting", ["client", "year", "season"])
  },
  mounted() {
    this.$store.dispatch("orders/getPaymentMethods");
  },
  methods: {
    changeCredit() {
      if (this.form.cashed) {
        this.form.credit = false;
      }
    },
    changeCashed() {
      if (this.form.credit) {
        this.form.cashed = false;
      }
    },
    async onSubmit() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (await valid) {
        const f = { ...this.form };
        f.due = moment(this.form.due).format("YYYY-MM-DD");
        f.order = this.$props.orderId;
        if (f.credit) f.import_value = -f.import_value;
        this.newPayment(f).then(status => {
          if (status == 201) {
            this.$set(this.$data, "form", {});
            window.location.href = `/amministrazione?client=${this.client}&season=${this.season}&year=${this.year}`;
          }
        });
      }
    },
    ...mapActions("orders", ["newPayment"])
  }
});
