









































































import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

import moment from "moment";

export default Vue.extend({
  name: "ExpectedOrdersNew",
  data() {
    return {
      form: { date: "" },
      rules: {
        client: [{ required: true, message: "Campo obbligatorio" }],
        date: [{ required: true, message: "Campo obbligatorio" }],
        brand: [{ required: true, message: "Campo obbligatorio" }],
        payment_method: [{ required: true, message: "Campo obbligatorio" }],
        number_of_pieces: [{ required: true, message: "Campo obbligatorio" }],
        total_sum: [{ required: true, message: "Campo obbligatorio" }],
      },
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("clients", ["clientsList"]),
    ...mapGetters("orders", ["paymentMethods"]),
    ...mapGetters("brands", ["brandsList"]),
  },
  mounted() {
    this.$store.dispatch("clients/getClientsList");
    this.$store.dispatch("orders/getPaymentMethods");
    this.$store.dispatch("brands/getBrandsList");
  },
  methods: {
    async onSubmit() {
      const valid = (
        this.$refs.form as Vue & {
          validate: () => boolean;
        }
      ).validate();

      console.log(await valid);
      if (await valid) {
        this.form.date = moment(this.form.date).format("YYYY-MM-DD");
        this.newExpectedOrder(this.form);
      }
    },
    ...mapActions("targets/orders", ["newExpectedOrder"]),
  },
});
