























import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "SettingsBrandTrends",
  data() {
    return { trendName: "" };
  },
  mounted() {
    this.getBrandTrendsList();
  },
  computed: {
    ...mapGetters("auth", ["me"]),
    ...mapGetters("brandtrends", ["brandTrendsList"]),
  },
  methods: {
    saveNewBrand() {
      this.newBrandTrend({ name: this.trendName }).then(
        () => (this.trendName = "")
      );
    },
    ...mapActions("brandtrends", [
      "getBrandTrendsList",
      "newBrandTrend",
      "editBrandTrend",
    ]),
  },
});
