































import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "SettingsBrands",
  data() {
    return { brandName: "" };
  },
  mounted() {
    this.getBrandsList();
  },
  computed: {
    ...mapGetters("auth", ["me"]),
    ...mapGetters("brands", ["brandsList"]),
  },
  methods: {
    saveNewBrand() {
      this.newBrand({ name: this.brandName }).then(() => (this.brandName = ""));
    },
    ...mapActions("brands", [
      "getBrandsList",
      "newBrand",
      "deleteBrand",
      "editBrand",
    ]),
  },
});
