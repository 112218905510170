















































import Vue from "vue";

import Header from "@/components/Header.vue";
import BlueTop from "@/components/BlueTop.vue";
import PrintRow from "@/components/PrintRow.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Companies",
  components: {
    "b-header": Header,
    "blue-top": BlueTop,
    "print-row": PrintRow
  },
  data() {
    return {
      printRowStatus: 0,
      fields: [
        { label: "anagrafica compagnia", text: "Anagrafica azienda" },
        { label: "dati", text: "Dati del cliente" },
        { label: "commissioni", text: "Commissioni" },
        { label: "responsabile", text: "Responsabile" },
        { label: "venditore", text: "Agente" },
        { label: "uscita", text: "Uscita" },
        { label: "indirizzi", text: "Indirizzi" },
        { label: "corrieri", text: "Corrieri" }
      ]
    };
  },
  created() {
    const query = this.$route.query;

    if (query["q"] && query.q.length >= 3) {
      this.$store.dispatch("companies/searchCompanies", query.q);
    } else {
      this.$store.dispatch("companies/getCompaniesList");
    }
  },
  computed: {
    ...mapGetters({ newOrderDialog: "dialogIsOpen" }),
    ...mapGetters("companies", { tableData: "companiesList" })
  },
  methods: {
    printRow(id: number) {
      this.printRowStatus = id;
    },
    editRow(id: number) {
      window.location.href = "/aziende/" + id;
    },
  }
});
