





























































































































import Vue from "vue";

import HistoryTable from "@/components/HistoryTable.vue";
import ExpectedOrdersNew from "@/components/ExpectedOrdersNew.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ExpectedOrdersTable",
  props: ["orders", "season", "year"],
  components: {
    "history-table": HistoryTable,
    "expected-orders-new": ExpectedOrdersNew,
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters("orders", ["ordersList"]),
    ...mapGetters("targets/orders", { orderVersions: "versionsList" }),
  },
  data() {
    return {
      clientStatuses: {
        CA: { type: "success", key: "Attivo" },
        CI: { type: "info", key: "Inattivo" },
        CS: { type: "danger", key: "Non acquisito" },
        CC: { type: "danger", key: "Chiuso" },
        CP: { type: "warning", key: "Potenziale" },
        CU: { type: "info", key: "Sospeso" },
      },
      orderStatutes: {
        IN: "In attesa",
        OE: "Evaso",
        OI: "Inevaso",
      },
      ordersHistoryId: 0,
      orderStatusId: 0,
      finalizeForm: {
        status: "IN",
        note: "",
        order: null,
      },
      finalizeRules: {
        status: [{ required: true, message: "Campo obbligatorio" }],
      },
      previsionDialog: false,
    };
  },
  methods: {
    showOrderHistory(id: number) {
      if (id > 0) this.$store.dispatch("targets/orders/getVersions", id);
      this.ordersHistoryId = id;
    },
    changeStatusModal(row: any) {
      if (this.me.role == 1) return;

      if (row) {
        this.$store.dispatch("orders/getOrdersList");
        this.finalizeForm = {
          status: row.status,
          note: row.note,
          order: row.order,
        };
        this.orderStatusId = row.id;
      } else {
        this.orderStatusId = 0;
      }
    },
    save() {
      this.$store.dispatch("targets/orders/finalize", {
        id: this.orderStatusId,
        form: this.finalizeForm,
      });
    },
    openPrevision() {
      this.previsionDialog = true;
    },
  },
});
