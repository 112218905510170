









import Vue from "vue";

export default Vue.extend({
  name: "GreyBanner",
  props: [],
  methods: {
    goback() {
      window.history.back();
    },
  },
});
