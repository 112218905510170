




























































































import Vue from "vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "TaskNewsAside",
  data() {
    return {
      action: "select",
      taskClient: false,
      taskCompany: false,
      task: {
        description: null,
        commercial: null,
        client: null,
        company: null,
      },
      taskRules: {
        description: [{ required: true, message: "Campo obbligatorio" }],
        commercial: [{ required: true, message: "Campo obbligatorio" }],
      },
      news: {},
      newsRules: {
        title: [{ required: true, message: "Campo obbligatorio" }],
        body: [{ required: true, message: "Campo obbligatorio" }],
        type: [{ required: true, message: "Campo obbligatorio" }],
      },
    };
  },
  computed: {
    ...mapGetters("clients", ["clientsList"]),
    ...mapGetters("companies", ["companiesList"]),
    ...mapGetters("users", ["usersList"]),
  },
  watch: {
    task: {
      handler: function(value) {
        if (value.client !== null && value.client !== "") {
          this.taskCompany = true
        } else {
          this.taskCompany = false
        }
        if (value.company !== null && value.company !== "") {
          this.taskClient = true
        } else {
          this.taskClient = false
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch("clients/getClientsList");
    this.$store.dispatch("companies/getCompaniesList");
    this.$store.dispatch("users/getUsersList");
  },
  methods: {
    closeTasks() {
      this.$emit("hide", false);
    },
    async saveTask() {
      const valid = (
        this.$refs.taskForm as Vue & {
          validate: () => boolean;
        }
      ).validate();

      if (await valid) {
        this.$store
          .dispatch("tasks/newTask", { ...this.task })
          .then((response) => {
            if (response.id) {
              this.$emit("hide", false);
            }
          });
      } else {
        return false;
      }
    },
    async saveNews() {
      const valid = (
        this.$refs.newsForm as Vue & {
          validate: () => boolean;
        }
      ).validate();

      if (await valid) {
        this.$store
          .dispatch("news/newNews", { ...this.news })
          .then((response) => {
            if (response.id) {
              this.$emit("hide", false);
            }
          });
      } else {
        return false;
      }
    },
  },
});
