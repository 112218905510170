







































































import Vue from "vue";

import Header from "@/components/Header.vue";
import BlueTop from "@/components/BlueTop.vue";
import PrintRow from "@/components/PrintRow.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Orders",
  components: {
    "b-header": Header,
    "blue-top": BlueTop,
    "print-row": PrintRow,
    "history-table": HistoryTable
  },
  data() {
    return {
      printRowStatus: 0,
      showedId: 0,
      fields: [
        { label: "cod", text: "Codice" },
        { label: "client", text: "Dati del cliente" },
        { label: "date", text: "Data" },
        { label: "company_line", text: "Mandante" },
        { label: "actual_delivery", text: "Consegnato effettivo" },
        { label: "commercial", text: "Agente" },
        { label: "season", text: "Stagione" },
        { label: "year", text: "Anno" },
        { label: "payment", text: "Pagamento" },
        { label: "num_of_pieces", text: "Num pezzi" },
        { label: "commissions", text: "Dettaglio provvigioni" },
        { label: "total_sum", text: "Importo" },
        { label: "conditions", text: "Condizioni" },
        { label: "note", text: "Note" }
      ],
      orderHistoryId: 0
    };
  },
  created() {
    this.$store.dispatch("orders/getOrdersList");
  },
  computed: {
    ...mapGetters("orders", { tableData: "ordersList" }),
    ...mapGetters("orders", ["versionsList"])
  },
  methods: {
    // `0` in `showedId` is used to reset the COD to expand
    toggleCod(id: number) {
      this.$data.showedId = this.$data.showedId == id ? 0 : id;
    },
    printRow(id: number) {
      this.printRowStatus = id;
    },
    editRow(id: number) {
      window.location.href = "/ordini/" + id;
    },
    showHistory(order: number) {
      if (order > 0) this.$store.dispatch("orders/getVersions", order);
      this.orderHistoryId = order;
    }
  }
});
