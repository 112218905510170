










import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";
import SettingsSidebar from "@/components/SettingsSidebar.vue";

export default Vue.extend({
  name: "Settings",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner,
    "settings-sidebar": SettingsSidebar
  }
});
