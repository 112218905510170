



















































import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";

import { mapGetters, mapActions } from "vuex";

import moment from "moment";

export default Vue.extend({
  name: "AccountingDetails",
  props: ["id"],
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner
  },
  data() {
    return {
      brand: "",
      payments: [{}],
      paymentsBackup: [{}],
      client: 0,
      year: 0,
      season: ""
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("orders", ["paymentMethods"])
  },
  async created() {
    await this.findOrder(this.$props.id).then((response: any) => {
      if (response.status != 200) {
        window.location.href = "/amministrazione";
      } else {
        const data = response.data;
        this.client = data.client.id;
        this.year = data.year;
        this.season = data.season;
        this.brand = data.brand.name;
        this.payments = data.payments.filter((x: any) => x.is_active);
        this.paymentsBackup = JSON.parse(
          JSON.stringify(data.payments.filter((x: any) => x.is_active))
        );
      }
    });
    this.$store.dispatch("orders/getPaymentMethods");
  },
  methods: {
    save() {
      for (const i in this.payments) {
        if (
          JSON.stringify(this.payments[i]) !=
          JSON.stringify(this.paymentsBackup[i])
        ) {
          const f: any = { ...this.payments[i] };

          f.due = moment(f.due).format("YYYY-MM-DD");
          this.editPayment(f).then((status: number) => {
            if (status == 200) {
              this.paymentsBackup[i] = this.payments[i];
            }
          });
        }
      }
    },
    goback() {
      let url = `/amministrazione?client=${this.client}&season=${this.season}&year=${this.year}`;

      window.location.href = url;
    },
    ...mapActions("orders", ["findOrder", "editPayment"])
  }
});
