

























import Vue from "vue";

import { mapGetters } from "vuex";

import Login from "@/components/Login.vue";
import OtpBox from "@/components/OtpBox.vue";

export default Vue.extend({
  name: "Home",
  components: {
    login: Login,
    "otp-box": OtpBox,
  },
  data() {
    return {
      loggedPath: "/dashboard",
    };
  },
  computed: {
    ...mapGetters("auth", ["isOTPSent"]),
  },
  mounted() {
    if (this.$route.query["ref"]) {
      this.loggedPath = String(this.$route.query.ref);
    }

    if (this.$store.getters["auth/isLogged"]) {
      window.location.href = this.loggedPath ?? "/dashboard";
    }
  },
});
