





































import Vue from "vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "PrintOrder",
  props: ["id", "fields", "dispatch", "defaultFields", "client", "action"],
  data() {
    return {
      checkAll: false,
      checkedFields: [""],
      emailSelectBox: false,
      isIndeterminate: false,
      cc_emails: []
    };
  },
  watch:  {
    $props: {
      handler: function (value) {
      this.checkedFields = (value['defaultFields']) ? value['defaultFields'] : []
      this.emailSelectBox = (value['action'] == 'email')
      },
      deep: true
    }, 

    cc_emails: function (value) {
      let lastElementEmail = value.at(-1)
      let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
      if (value.length !== 0) {
        if (regex.test(lastElementEmail) !== true) {
          value.pop() 
        }
      } 
    }
  },
  created() {
    this.checkedFields = (this.$props['defaultFields']) ? this.$props['defaultFields'] : []
      this.emailSelectBox = (this.$props['action'] == 'email')
  },
  computed: {
    ...mapGetters(["inLoading"])
  },
  methods: {
    handleCheckAllChange(val: boolean) {
      this.checkedFields = val ? this.fields.map((x: any) => x.label) : [];
      this.isIndeterminate = false;
    },
    // eslint-disable-next-line
    handleCheckedFieldsChange(value: any) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fields.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.fields.length;
    },
    printFile() {
      this.$store.dispatch(this.dispatch, {
        id: this.id,
        client: this.client,
        fields: this.checkedFields,
        cc_emails: this.cc_emails
      });
    }
  }
});
