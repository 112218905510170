









import Vue from "vue";

import Header from "@/components/Header.vue";

export default Vue.extend({
  name: "Calendar",
  components: {
    "b-header": Header,
  },
  data() {
    return {
      calendar: "",
    };
  },
  created() {
    this.$store.dispatch("profiles/findProfile", "me").then((response) => {
      this.calendar = response.data.calendar_iframe;
    });
  },
});
