













































































import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

import moment from "moment";

export default Vue.extend({
  name: "AppointmentNew",
  props: ["appointment", "createPlan"],
  data() {
    return {
      form: { date: "", date_type: "" },
      plan: { season: null, year: null },
      rules: {
        date: [{ required: true, message: "Campo obbligatorio" }],
        date_type: [{ required: true, message: "Campo obbligatorio" }],
        location: [{ required: true, message: "Campo obbligatorio" }]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "Oggi",
            onClick(picker: any) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "Domani",
            onClick(picker: any) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "Fra una settimana",
            onClick(picker: any) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          },
          {
            text: "Fra un mese",
            onClick(picker: any) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", date);
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("planning", ["plansList"])
  },
  watch: {
    appointment: function(value) {
      this.checkAppointment(value);
    }
  },
  mounted() {
    this.checkAppointment(this.appointment);
  },
  methods: {
    checkAppointment(value: any) {
      if (value.plan) {
        this.$set(this.$data, "form", {
          date_type: value.date_type,
          note: value.note,
          confirmed: value.confirmed
        });
      }
      if (value.date) {
        const date = moment(value.date).subtract(2, "hours");
        this.$set(this.$data.form, "date", date);
      }
      if (value.date_type) {
        this.$set(this.$data.form, "date_type", value.date_type);
      }
      if (value.location) {
        this.$set(this.$data.form, "location", value.location);
      }
    },
    async onSubmit() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (await valid) {
        this.form.date = moment(this.form.date).format("YYYY-MM-DD HH:mm");
        if (this.appointment.id) {
          this.editAppoinment({ ...this.appointment, ...this.form }).then(
            (status: number) => {
              if (status == 200) {
                this.searchPlannings(null);
              }
            }
          );
        } else {
          this.newAppointment({ ...this.appointment, ...this.form }).then(
            (status: number) => {
              if (status == 201) {
                this.searchPlannings(null);
              }
            }
          );
        }
      }
    },
    async filterPlanning() {
      if (!(this.plan.season && this.plan.year)) return;

      await this.$store.dispatch("planning/searchPlannings", this.plan);
    },
    ...mapActions("planning", [
      "newAppointment",
      "editAppoinment",
      "searchPlannings"
    ])
  }
});
